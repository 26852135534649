import dynamic from "next/dynamic";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, FreeMode } from "swiper";
import { useState, useRef, useEffect } from "react";
import { getLanguage } from "localization/i8intl";
import { useWindowSize } from "api/commonConstants";
import TopPodcastContainer from "./../topPodcastContainer";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";

const NewsTopbar = dynamic(() =>
  import("../../../components/newsCard/newsTopbar")
);
const TopShowsWidget = ({ TopShowsWidgetData, topPodcastData }) => {
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [language, setLanguage] = useState("");
  const size = useWindowSize();

  useEffect(() => {
    setLanguage(getLanguage());
  }, []);

  const showCard = (data, index) => {
    return (
      <a
        href={`${process.env.NEXT_PUBLIC_BASE_URL}/${data.categorySlug}/post/${data.postSlug}`}
      >
        <div className="showcard-widget">
          <div className="showcard-title">{data?.title}</div>
          <img src={data?.headingImageUrl} />
        </div>
      </a>
    );
  };
  return (
    <>
      <div
        className="outer-main-conainer"
        style={{
          marginTop: "1.5rem",
          marginBottom: "1.5rem",
        }}
      >
        <div className="main-containerTopShow show-widget-alignment">
          <div className="home-left-align">
            {" "}
            <div className="topShowsTopBar">
              <NewsTopbar
                videoEn={"Top Shows"}
                videoHn={"टॉप शोज़"}
                newsIcon={"videoShow"}
                newsBgclassName={"videoShow"}
                newsBgClass={"videoShowTopBar"}
                videoTitleClass={"videoTitle"}
                videoImgClass={"videoImg"}
                videoLinkClass={"videoLink"}
              />
            </div>
            <div className="checkMobileOrNot" style={{ marginTop: "-8px" }}>
              <div className="top-shows-widget-container ">
                {TopShowsWidgetData?.map((data, index) => {
                  console.log("TopShowsWidgetData11", data);

                  return (
                    <div
                      key={index}
                      className="top-shows-indi-widget-container"
                    >
                      <div className="widget-header">
                        <img
                          className="header-image"
                          src={data?.headingImageUrl}
                        />
                        <div className="header-right">
                          <div className="header-right-parta">
                            {/* <span className="follow-text">Follow</span> */}
                            <span className="episode-text">{`${
                              data?.episodesCount
                            } ${
                              language === "hi" ? "एपिसोड्स " : "Episodes"
                            }`}</span>
                          </div>
                          {/* <div className="header-right-partb">
                          <div className="follow-icon">+</div>
                        </div> */}
                        </div>
                      </div>
                      <div className="widget-latest-strip">
                        <span>
                          {`${
                            language === "hi"
                              ? data?.titleHn + " - लेटेस्ट एपिसोड्स"
                              : data?.title + " - Latest Episodes"
                          }`}
                        </span>{" "}
                      </div>
                      <div className="widget-show-cards">
                        {data?.episodes?.map((episodeData, index1) => {
                          return (
                            <> {index1 <= 2 && showCard(episodeData, index1)}</>
                          );
                        })}
                      </div>
                      <div className="line-seperator"></div>
                      <div className="read-more-container">
                        <a
                          href={`${process.env.NEXT_PUBLIC_BASE_URL}/show/detail/${data?.postSlug}`}
                          style={{ marginLeft: "auto" }}
                        >
                          <div className="read-more-inner">
                            <span>
                              {language === "hi" ? "और देखेंं " : "View More"}
                            </span>
                            <img
                              src={
                                "https://static.thelallantop.com/images/post/1714633736763_vector_4.webp"
                              }
                              alt="icon-click"
                            ></img>
                          </div>
                        </a>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="checkDesktopOrNot" style={{ marginTop: "-8px" }}>
              <Swiper
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  400: {
                    slidesPerView: 2,
                  },
                  639: {
                    slidesPerView: 2,
                  },
                  865: {
                    slidesPerView: 2,
                  },
                  1000: {
                    slidesPerView: 1.1,
                  },
                  1200: {
                    slidesPerView: 2.2,
                  },
                  1350: {
                    slidesPerView: 2.2,
                  },
                  1500: {
                    slidesPerView: 2.5,
                  },
                  1700: {
                    slidesPerView: 2.8,
                  },
                }}
                spaceBetween={16}
                freeMode={true}
                // navigation={true}
                pagination={{
                  clickable: true,
                }}
                onInit={(swiper) => {
                  swiper.params.navigation.prevEl = prevRef.current;
                  swiper.params.navigation.nextEl = nextRef.current;
                  console.log(prevRef.current);
                  console.log(nextRef.current);
                  swiper.on("slideChange", () => {
                    setIsBeginning(swiper.isBeginning);
                    setIsEnd(swiper.isEnd);
                  });
                  swiper.navigation.init();
                  swiper.navigation.update();
                }}
                modules={[FreeMode, Navigation]}
                className="mySwiper shorts-slider"
              >
                {TopShowsWidgetData?.map((data, index) => (
                  <>
                    {console.log("data?.length ", data)}
                    {data?.episodes?.length > 0 && (
                      <SwiperSlide key={index} index={index}>
                        <div className="top-shows-indi-widget-container">
                          <div className="widget-header">
                            <img
                              className="header-image"
                              src={data?.headingImageUrl}
                            />
                            <div className="header-right">
                              <div className="header-right-parta">
                                {/* <span className="follow-text">Follow</span> */}
                                <span className="episode-text">{`${
                                  data?.episodesCount
                                } ${
                                  language === "hi" ? "एपिसोड्स " : "Episodes"
                                }`}</span>{" "}
                              </div>
                              {/* <div className="header-right-partb">
                        <div className="follow-icon">+</div>
                      </div> */}
                            </div>
                          </div>
                          <div className="widget-latest-strip">
                            <span>
                              {`${
                                language === "hi"
                                  ? data?.titleHn + " - लेटेस्ट एपिसोड्स"
                                  : data?.title + " - Latest Episodes"
                              }`}
                            </span>{" "}
                          </div>
                          <div className="widget-show-cards">
                            {data?.episodes?.map((episodeData, index1) => {
                              return (
                                <>
                                  {" "}
                                  {index1 <= 2 && showCard(episodeData, index1)}
                                </>
                              );
                            })}
                          </div>

                          <div className="read-more-container">
                            <a
                              href={`${process.env.NEXT_PUBLIC_BASE_URL}/show/detail/${data?.postSlug}`}
                              style={{ marginLeft: "auto" }}
                            >
                              <div className="read-more-inner">
                                <span>
                                  {language === "hi"
                                    ? "और देखेंं  "
                                    : "View More"}
                                </span>{" "}
                                <img
                                  src={
                                    "https://static.thelallantop.com/images/post/1714633736763_vector_4.webp"
                                  }
                                  alt="icon-click"
                                ></img>
                              </div>
                            </a>
                          </div>
                        </div>
                      </SwiperSlide>
                    )}
                  </>
                ))}
                {/* <button onClick={() => swiper?.slidePrev()}>previous</button> */}
                {
                  <div
                    className="shorts-slider-button-bwd"
                    style={
                      !isBeginning ? { display: "block" } : { display: "none" }
                    }
                    ref={prevRef}
                  >
                    <span>{"<"}</span>
                  </div>
                }
                {
                  <div
                    className="shorts-slider-button-fwd"
                    style={!isEnd ? { display: "block" } : { display: "none" }}
                    ref={nextRef}
                  >
                    <span>{">"}</span>
                  </div>
                }
              </Swiper>
            </div>
          </div>
          {size.width <= 499 && <div className="gray-home-seperator"></div>}
          <div className="home-right-align">
            <TopPodcastContainer topPodcastData={topPodcastData} />
          </div>
        </div>
      </div>
    </>
  );
};
export default TopShowsWidget;
